import React, { useState, useEffect, useRef } from 'react';
import { Form, FormGroup, Button, Input } from 'reactstrap';
import moment from 'moment';
import db from './firebase/firebase';
import addNotification from 'react-push-notification';
import { useParams } from "react-router-dom";

const getTimeSpentLabel = (seconds) => {
    if (seconds < 60) {
        return seconds + " sec";
    } else if (seconds < 3600) {
        return "" + (seconds / 60).toFixed(1) + " min";
    } else if (seconds < 86400) {
        return "" + (seconds / 3600).toFixed(1) + " hour";
    }
}

let bidderStatus = 'Away';
let timerStarted = false;
let activeTimestamp = 0;

export const Bid = () => {
    let { param } = useParams();
    console.log('param: ' + param);

    const [user, setUser] = useState(param);
    const [status, setStatus] = useState('Away');
    const [link, setLink] = useState('');
    const [cat, setCat] = useState('Mobile');
    const textAreaRef = useRef(null);
    const [proposals, setProposals] = useState([]);
    const [proposal, setProposal] = useState('');
    const [date, setDate] = useState(moment().format('DD-MM-YYYY'));
    const [models, setModels] = useState([]);
    const [monthBids, setMonthBids] = useState(0);
    const [timeSpent, setTimeSpent] = useState(0);

    const fixDemoUrls = (text)=>{
      let t = text;

      t = t.replace('/yxsjqoxn','/yyfcy7tn'); // fura android
      t = t.replace('/y3cftxm7', '/y5tly5ut'); // simplu android
      t = t.replace('/y5alwumx', '/yyth3k3f'); // bindiya android
      t = t.replace('/yytl795g', '/yyepqk8q'); // shutter android

      t = t.replace('/y4spajao', '/yxbqpu8k'); // forest ios
      t = t.replace('/y6ev62bz', '/y576hf6x'); // simplu ios
      t = t.replace('/y2n32y5k', '/yypdcp2a'); // fitness ios
      t = t.replace('/y4dzccq3', '/yxwcya8p'); // diyg ios

      return t;
    };

    const showNotification = (status) => {
        console.log('showNotification');
        addNotification({
            title: 'Bidder: ' + status,
            subtitle: 'Bidder: ' + status,
            message: 'SaifTech',
            theme: 'darkblue',
            native: true, // when using native, your OS will handle theming.
            silent: false
        });
    };

    const startTimer = () => {
        console.log('startTimer');
        setInterval(() => {
            // check bidder status changed
            loadBidderStatus();
        }, 5000);
    };

    const updateStatus = (status) => {
        console.log('updateStatus');
        db.collection('bidders').doc(user)
            .set({ status: status }, { merge: true })
            .then(() => {
                //
            })
            .catch(error => {
                console.log('error: ' + error);
            });
        if (user === 'Bidder') {
            if (status === 'Active') {
                activeTimestamp = new Date().getTime();
            }
            else {
                // add status timestamp
                db.collection('status_logs')
                    .add({
                        status: status,
                        time: new Date().getTime() - activeTimestamp,
                        date: moment().format('DD-MM-YYYY'),
                    })
                    .then(() => {
                        //
                    })
                    .catch(error => {
                        console.log('error: ' + error);
                    });
            }
        }
    };

    const addBid = (proposalTxt) => {
        console.log('addBid');
        db.collection('bids')
            .add({
                timestamp: new Date().getTime(),
                date: moment().format('DD-MM-YYYY'),
                time: moment().format('hh:mm a'),
                user: user,
                link: link,
                proposal: proposalTxt,
            })
            .then(() => {
                // success
                loadBids();
            })
            .catch(error => {
                // error
            });
        setLink('')
        setCat('Mobile')
        setProposal('')
    };

    const loadCurrentUserStatus = () => {
        console.log('loadCurrentUserStatus');
        db.collection('bidders')
            .get()
            .then(querySnapshot => {
                const users = querySnapshot.docs.map(doc => doc.data());
                for (let i = 0; i < users.length; i++) {
                    const model = users[i];
                    if (model.name === user) {
                        setStatus(model.status);
                    }
                }
            });
    };

    const loadBidderStatus = () => {
        console.log('loadBidderStatus');
        db.collection('bidders')
            .get()
            .then(querySnapshot => {
                const users = querySnapshot.docs.map(doc => doc.data());
                for (let i = 0; i < users.length; i++) {
                    const model = users[i];
                    if (model.name === 'Bidder') {
                        console.log('bidderStatus1: ' + bidderStatus);
                        console.log('bidderStatus2: ' + model.status);
                        if (model.status !== bidderStatus) {
                            // bidder status changed show notification
                            showNotification(model.status)
                        }
                        bidderStatus = model.status;
                    }
                }
            });
    };

    const loadProposals = () => {
        console.log('loadProposals');
        db.collection('proposals')
            .get()
            .then(querySnapshot => {
                const models = querySnapshot.docs.map(doc => doc.data());
                setProposals(models);
            });
    };

    const loadMonthBids = () => {
        console.log('loadMonthBids');
        // last 30 day bids
        let monthStart = moment().subtract(30, 'days');
        console.log('monthStart1: ' + monthStart);
        console.log('monthStart2: ' + moment(monthStart).format('DD-MM-YYYY'));
        // get all bids having timestamp > monthStart
        db.collection('bids')
            .where('user', '==', user)
            .where('timestamp', '>', parseInt('' + monthStart))
            .get()
            .then(querySnapshot => {
                const bids = querySnapshot.docs.map(doc => doc.data());
                setMonthBids(bids.length);
            });
    };

    const loadBids = () => {
        console.log('loadBids');
        db.collection('bids')
            .where('user', '==', user)
            .where('date', '==', date)
            .orderBy('timestamp', 'asc')
            .get()
            .then(querySnapshot => {
                const models = querySnapshot.docs.map(doc => doc.data());
                setModels(models);
                loadMonthBids();
            });
    };

    const loadTodayTimeSpent = () => {
        console.log('loadTodayTimeSpent');
        db.collection('status_logs')
            .where('date', '==', date)
            .get()
            .then(querySnapshot => {
                const logs = querySnapshot.docs.map(doc => doc.data());
                let millis = 0;
                for (let i = 0; i < logs.length; i++) {
                    const log = logs[i];
                    millis = millis + log.time;
                }
                let label = getTimeSpentLabel(millis / 1000);
                setTimeSpent(label);
            });
    };

    useEffect(() => {
        console.log('useEffect');
        loadCurrentUserStatus();
        loadProposals();
        loadBids();
        if (user === 'Bidder') {
            loadTodayTimeSpent();
        }
        if (!timerStarted) {
            startTimer();
            timerStarted = true;
        }
    }, [user]);

    // let proposalTxt = proposal.replace(/_b/g, '\n');
    // if(user === 'Saif'){
    //     proposalTxt = proposal.replace(/_b/g, '\n')+'\nSaif'
    // }

    return (
        <div className='body_wrapper column'>
            <div style={{ backgroundColor: '#fafafa' }}>
                <div className={`container`} style={{ display: 'flex', flexDirection: 'row' }}>
                    <a className="navbar-brand logo_h" href="/">
                        <img src={require("./image/logo-1.png")} style={{ height: 40 }} alt="" />
                    </a>
                    <FormGroup style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 0 }}>
                        <Input
                            style={{ width: 120 }}
                            type='select'
                            placeholder='User'
                            value={user}
                            onChange={(event) => {
                                setUser(event.target.value);
                            }}>
                            <option>Saif</option>
                            <option>Bidder</option>
                        </Input>
                        <Input
                            style={{ width: 120, marginLeft: 10 }}
                            type='select'
                            placeholder='Status'
                            value={status}
                            onChange={(event) => {
                                setStatus(event.target.value);
                                updateStatus(event.target.value);
                            }}>
                            <option>Active</option>
                            <option>Away</option>
                        </Input>
                    </FormGroup>
                </div>
            </div>

            <div style={{ backgroundColor: '#fff' }}>
                <div className="container" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='column' style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form style={{ width: '100%', marginTop: 30, marginBottom: 30 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Make bid Proposal</h4>
                                </div>
                                {/* <FormGroup>
                                    <Input
                                        type='text'
                                        placeholder='Project Link'
                                        value={link}
                                        onChange={(event) => { setLink(event.target.value) }}>
                                    </Input>
                                </FormGroup> */}
                                <FormGroup style={{ display: 'flex', marginTop: 30, flexDirection: 'row', justifyContent: 'center' }}>
                                    <FormGroup style={{ width: 200, marginBottom: 0 }}>
                                        <Input
                                            type='select'
                                            placeholder='Type'
                                            value={cat}
                                            onChange={(event) => {
                                                setCat(event.target.value);
                                            }}>
                                            <option>Mobile</option>
                                            <option>Android</option>
                                            <option>iOS</option>
                                            <option>Flutter</option>
                                            <option>React Native</option>
                                            <option>Fix Bugs</option>
                                            <option>Add Features</option>
                                            <option>Web</option>
                                        </Input>
                                    </FormGroup>
                                    <Button
                                        style={{ width: 200, marginLeft: 10, marginTop: 0 }}
                                        className='btn-block btn-dark btn-submit'
                                        onClick={() => {
                                            let texts = []
                                            for (let i = 0; i < proposals.length; i++) {
                                                const proposal = proposals[i]
                                                if (proposal.cat === cat) {
                                                    texts.push(proposal.text)
                                                }
                                            }
                                            let randIndex = Math.floor(Math.random() * texts.length)
                                            let text = texts[randIndex]
                                            if (user === 'Saif') {
                                                text = fixDemoUrls(text);
                                                setProposal(text.replace(/_b/g, '\n') + '\n Saif\n www.saiftech.org')
                                            }
                                            else {
                                                text = fixDemoUrls(text);
                                                setProposal(text.replace(/_b/g, '\n'))
                                            }
                                        }}>
                                        Load Proposal
                                    </Button>
                                    <Button
                                        style={{ width: 200, marginLeft: 10, marginTop: 0 }}
                                        className='btn-block btn-dark btn-submit'
                                        onClick={() => {
                                            navigator.clipboard.writeText(proposal)
                                            addBid(proposal)
                                        }}>
                                        Copy
                                    </Button>
                                </FormGroup>

                                {
                                    document.queryCommandSupported('copy') &&
                                    <FormGroup>
                                        {/* <div contenteditable="true" ref={textAreaRef}>
                                            {lines.map((line)=>{
                                                return line;
                                            })}
                                        </div> */}
                                        <Input
                                            ref={textAreaRef}
                                            style={{ height: 130 }}
                                            type='textarea'
                                            placeholder='Proposal'
                                            value={proposal}
                                            onChange={(event) => { setProposal(event.target.value) }}>
                                        </Input>
                                    </FormGroup>
                                }

                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: 1, backgroundColor: 'lightgrey' }} />
            <div style={{ paddingTop: 20, paddingBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: '#fafafa' }}>
                <div>
                    <h4>Bids Summary</h4>
                </div>
                <div className='row' style={{ marginTop: 15 }}>
                    <h6>Bids in Last 30 days:</h6>
                    <h6 style={{ marginLeft: 10, color: 'green', fontWeight: 'bold' }}>{monthBids}</h6>
                </div>
                <FormGroup style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                    <Input
                        style={{ textAlign: 'center' }}
                        type='text'
                        placeholder='Date'
                        value={date}
                        onChange={(event) => {
                            setDate(event.target.value);
                        }}>
                    </Input>
                    <Button
                        style={{ width: 100, marginLeft: 10, marginTop: 0 }}
                        className='btn-block btn-dark btn-submit'
                        onClick={() => {
                            loadBids();
                            loadTodayTimeSpent();
                        }}>
                        Load
                    </Button>
                </FormGroup>

                <div className='row' style={{ marginTop: 5 }}>
                    <h6>Bids Today:</h6>
                    <h6 style={{ marginLeft: 10, color: 'green', fontWeight: 'bold' }}>{models.length}</h6>
                </div>

                {user === 'Bidder' ?
                    <div className='row' style={{ marginTop: 5 }}>
                        <h6>Time Spent:</h6>
                        <h6 style={{ marginLeft: 10, color: 'green', fontWeight: 'bold' }}>{timeSpent}</h6>
                    </div>
                    :
                    null
                }

                {models.map((model, index) => {
                    return (
                        <div style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                            <div>{model.time}</div>
                            <a href={model.link} style={{ color: 'blue' }}>{model.link}</a>
                            <div style={{ color: 'black' }}>{model.proposal.substring(0, 200)}...</div>
                            <div style={{ width: '100%', height: 1, backgroundColor: 'lightgrey', marginTop: 10 }} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}