import React from "react";
import emailjs from "emailjs-com";
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';
import db from '../firebase/firebase';
import { Input } from 'reactstrap';

class MyForm extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    name: '',
    email: '',
    message: '',
    toast: false,
  }

  sendEmail = () => {
    if (this.state.name === ''
      || this.state.email === ''
      || this.state.message === '') {
      console.log('fill form');
      this.showToast('Please enter info')
      return
    }

    setTimeout(() => {
      // show success
      db.collection('messages').add({
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      });
      this.setState({ toast: true })
      setTimeout(() => {
        this.setState({ toast: false })
      }, 3000)
    }, 1000);

    let mailParams = {
      "accessToken": '03X4H03hf8mNX3vmdhiLI',
      "service_id": 'service_vkaxqvg',
      "template_id": 'template_mwyz8jd',
      "user_id": 'Hrb9PrGzCxo7wOfqg',
      "template_params": {
        "from_name": this.state.name,
        "email": this.state.email,
        "message": this.state.message,
      }
    }
    fetch('https://api.emailjs.com/api/v1.0/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(mailParams)
    })
      .then(response => response.text())
      .then(result => {
        console.log('sent: ' + result.text);
        this.setState({ name: '', email: '', message: '' })
      })
      .catch(err => {
        console.log(err)
      });
  }

  showToast = (msg) => {
    let config = {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    };
    toast.success(msg, config);
  };

  render() {
    return (
      <div>
        <div className="form_class" style={{ marginTop: -30 }}>
          <div className="row">

            <div className="col-lg-6">
              <Input
                name="name"
                style={{ marginBottom: 10, fontSize: 18 }}
                type='text'
                className="form-control"
                placeholder='Your Name *'
                value={this.state.name}
                onChange={(event) => {
                  console.log('change: ' + event.target.value);
                  this.setState({ name: event.target.value })
                }} />
            </div>

            <div className="col-lg-6" style={{ marginBottom: 10 }}>
              <Input
                style={{ fontSize: 18 }}
                name="email"
                type='email'
                className="form-control"
                placeholder='Your Email *'
                value={this.state.email}
                onChange={(event) => {
                  console.log('change: ' + event.target.value);
                  this.setState({ email: event.target.value })
                }} />
            </div>
          </div>

          <Input
            style={{ marginTop: 0, fontSize: 18 }}
            name="message"
            type='textarea'
            placeholder='Your message...'
            className="form-control"
            rows="6"
            value={this.state.message}
            onChange={(event) => {
              console.log('change: ' + event.target.value);
              this.setState({ message: event.target.value })
            }} />

          <button
            onClick={this.sendEmail}
            className="btn my-button" style={{ color: 'white', marginTop: 10, width: 150, padding: 12, fontSize: 16, border: 'none' }}>
            Send Message
          </button>

        </div>

        {this.state.toast ?
          <Fade bottom cascade duration={500}>
            <div style={{ marginTop: 5, color: 'green' }}>
              Message Sent! Soon we will reply you back :)
            </div>
          </Fade>
          :
          null
        }

      </div>
    );
  }
}

export default MyForm;
