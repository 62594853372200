import db from '../../firebase/firebase';
import firebase from "firebase/compat/app";
import { encode } from "base-64";

const Api = {

    placeShopifyOrder: (obj, success, error) => {
        db.collection('shopifyOrders')
            .add(obj)
            .then((result) => {
                success(result.id)
            })
            .catch(e => {
                error(e.message)
            });
    },

    getCredentials: (success, error) => {
        db.collection('credentials')
            .get()
            .then((results) => {
                let credentials = {}
                results.forEach(doc => {
                    credentials[doc.id] = doc.data()
                });
                success(credentials)
            })
            .catch(e => {
                error(e.message)
            });
    },

    stripeToken: async function (key, number, exp_month, exp_year, cvc, onSuccess, onError) {
        let url = 'https://api.stripe.com/v1/tokens'
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + key
        }
        let params = {
            'card[number]': number,
            'card[exp_month]': exp_month,
            'card[exp_year]': exp_year,
            'card[cvc]': cvc
        }
        var formBody = [];
        for (var property in params) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(params[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log('formBody: ' + formBody);
        // remove all white spaces
        formBody = formBody.replace(/%20/g, "");
        apiCall(url, formBody, 'POST', headers, onSuccess, onError)
    },

    stripePayment: async function (key, tokenId, amount, onSuccess, onError) {
        let url = 'https://api.stripe.com/v1/charges'
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + key
        }
        let params = {
            'source': tokenId,
            'amount': amount * 100,
            'currency': 'USD',
        }
        var formBody = [];
        for (var property in params) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(params[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log('formBody: ' + formBody);
        apiCall(url, formBody, 'POST', headers, onSuccess, onError)
    },
}

const apiCall = (url, params, method, headers, onSuccess, onError) => {
    // console.log('url: ' + url);
    fetch(url, {
        method: method,
        headers: headers,
        body: params
    })
        .then(response => response.json())
        .then(result => {
            // console.log(JSON.stringify(result));
            onSuccess(result);
        })
        .catch(err => {
            console.log(err)
            onError(err);
        });
};

export default Api