import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import showToast from '../toast';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import { Form, FormGroup, Button, Input } from 'reactstrap';
import db from '../../firebase/firebase';
// const { getJson } = require("serpapi");

const Success = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const [storesCount, setStoresCount] = useState(0);
    const [apiHits, setApiHits] = useState(0);
    const [keyword, setKeyword] = useState('beauty');
    const [start, setStart] = useState(false);
    const [startOffset, setStartOffset] = useState(0);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        db.collection('scraping-stats')
            .doc(keyword)
            .get()
            .then(res => {
                let obj = res.data()
                if (obj) {
                    setStoresCount(obj.storesCount)
                    setApiHits(obj.apiHits)
                }
            });
    }

    // https://cryptic-island-28023-9c77c120738b.herokuapp.com/
    const startScraping = () => {
        console.log('startScraping1');
        // https://www.google.com/search?q=intext%3A%22powered+by+shopify%22intext%3A%22skincare%22&oq=intext%3A%22powered+by+shopify%22intext%3A%22skincare%22&uule=w+CAIQICIaQXVzdGluLFRleGFzLFVuaXRlZCBTdGF0ZXM&hl=en&gl=us&num=100&start=0&sourceid=chrome&ie=UTF-8
        // let url = 'https://serpapi.com/search.json?engine=google&q=intext%3A%22powered+by+shopify%22intext%3A%22' + keyword + '%22&api_key=' + apiKey + '&cr=countryUS|countryAU|countryCA|countryUK|countryNZ|countryBR|countryIT|countryDE|countryES|countryFR|countryIE|countryFI|countryAE|countryKW|countryQA|countryZA&start=' + startOffset + '&num=100'
        const url = 'https://startscraping-fxhiip3qka-uc.a.run.app';
        let cr = 'countryUS|countryAU|countryCA|countryUK|countryNZ|countryBR|countryIT|countryDE|countryES|countryFR|countryIE|countryFI|countryAE|countryKW|countryQA|countryZA'
        let q = 'intext:"powered by shopify"intext:"beauty"'
        let headers = {
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
            'Origin': 'localhost:3000'
        }
        let params = {
            q: q, 
            cr: cr,
            start: startOffset,
            num: 10,
        }
        fetch(url, {
            // mode: 'no-cors',
            // credentials: 'include',
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        })
            .then(response => {
                console.log('1: '+response.status);
                console.log('2: '+response.statusText);
                console.log('3: '+response.ok);
                return response.text()
            })
            .then(data => {
                console.log('data: '+data);
            })
            .catch(error => {
                console.error('Error:', error);
            });

        // Get the first page
        // const page = await getJson({ engine: "google", q: "Coffee" });
        // // Parse SerpApi search URL to the next page
        // const nextUrl = new URL(page.serpapi_pagination.next);
        // // Extract the request parameters
        // const nextParams = Object.fromEntries(nextUrl.searchParams);
        // // Get the next page
        // const nextPage = await getJson(nextParams);
        // console.log(nextPage);
    }

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', marginTop: 100, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginLeft: 10, color: '#113E49' }}>
                    SERP Scraper
                </h3>
            </div>
            <div className='shopify-step-parent' style={{ height: '80vh', display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column', padding: 50, border: '1px solid lightgray', borderRadius: 10 }}>
                    <div style={{ fontSize: 22, textAlign: 'center' }}>
                        Stores: {storesCount}
                    </div>
                    <div style={{ fontSize: 22, marginTop: 10, textAlign: 'center' }}>
                        Api Calls: {apiHits}
                    </div>
                    <Input
                        style={{ marginTop: 15 }}
                        type='text'
                        placeholder='Keyword'
                        value={keyword}
                        onChange={(event) => { setKeyword(event.target.value) }}>
                    </Input>
                    {!start &&
                        <button
                            style={{ alignSelf: 'center', marginTop: 20, height: 40, width: 200 }}
                            onClick={() => {
                                setStart(true)
                                startScraping()
                            }}>
                            Start
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default Success;