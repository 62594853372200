import React, { Component } from 'react';
import Sticky from 'react-stickynode';
import { Link } from 'react-scroll';

class NavbarShopifyToApp extends Component {
    state = {
        toggle: false,
    }

    render() {
        var { mClass, mContainer, mainlogo, stickylogo } = this.props;
        return (
            <div>
                <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                    <nav className={`navbar navbar-expand-lg navbar-light ${mClass} navbar-color nav-height`}>
                        <div className={`container my-nav nav-height ${mContainer}`}>

                            <a className="navbar-brand logo_h" href="/" style={{ marginTop: 10 }}>
                                <img style={{ maxWidth: 180, maxHeight: 80 }} src={require("../../image/" + mainlogo)} alt="" />
                                <img style={{ maxWidth: 180, maxHeight: 80 }} src={require("../../image/" + stickylogo)} alt="" />
                            </a>

                            <div
                                onClick={() => {
                                    this.setState({ toggle: !this.state.toggle })
                                }}
                                className="navbar-toggler" style={{ outline: 'none', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <img
                                    className='toggle-icon'
                                    style={{ width: this.state.toggle ? 40 : 35, height: this.state.toggle ? 40 : 35, outline: 'none', outlineColor: 'transparent', outlineWidth: 0 }}
                                    src={this.state.toggle ? require("../../image/cross_icon.png") : require("../../image/menu_icon.png")} />
                            </div>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="nav navbar-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link" style={{ color: '#0db3c7' }} activeClass="active" to="features" spy={true} smooth={false} offset={-66} duration={500}>
                                            Features
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" style={{ color: '#0db3c7' }} activeClass="active" to="pricing" spy={true} smooth={false} offset={-66} duration={500}>
                                            Pricing
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" style={{ color: '#0db3c7' }} activeClass="active" to="showcase" spy={true} smooth={false} offset={-66} duration={500}>
                                            Showcase
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" style={{ color: '#0db3c7' }} activeClass="active" to="FAQ" spy={true} smooth={false} offset={-66} duration={1000}>
                                            FAQs
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            {this.state.toggle &&
                                <ul className="nav navbar-nav animate-down" style={{ marginTop: 0, display: 'flex', width: '100%', border: '1px solid lightgray', backgroundColor: 'white' }}>
                                    <li className="nav-item">
                                        <Link
                                            onClick={() => {
                                                this.setState({ toggle: false })
                                            }}
                                            className="nav-link" activeClass="active" to="features" spy={true} smooth={false} offset={-66} duration={500}>
                                            Features
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            onClick={() => {
                                                this.setState({ toggle: false })
                                            }}
                                            className="nav-link" activeClass="active" to="pricing" spy={true} smooth={false} offset={-66} duration={500}>
                                            Pricing
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            onClick={() => {
                                                this.setState({ toggle: false })
                                            }}
                                            className="nav-link" activeClass="active" to="showcase" spy={true} smooth={false} offset={-66} duration={500}>
                                            Showcase
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            onClick={() => {
                                                this.setState({ toggle: false })
                                            }}
                                            className="nav-link" activeClass="active" to="FAQ" spy={true} smooth={false} offset={-66} duration={1000}>
                                            FAQs
                                        </Link>
                                    </li>
                                </ul>
                            }

                        </div>
                    </nav>

                </Sticky>
            </div>
        );
    }
}

export default NavbarShopifyToApp;