import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';
import CounterItem from '../Skill/CounterItem';
const Reveal = require("react-reveal/Reveal")

class Skill extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = { count: 0 };
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: 'easeOutCubic',
        update() {
          el.innerText = data.count.toLocaleString();
        }
      });

    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll('[data-countup]');

    els.forEach(makeCountup);
  }
  render() {
    var { cClass } = this.props;
    return (
      <section className={`project_count_area ${cClass}`} style={{ paddingTop: 50, paddingBottom: 50 }}>
        <div className="container" style={{}}>
          <Reveal effect="fadeInUp">
            <div className="row">
              <CounterItem CText='150' pdescription='Total Projects' icon='briefcase' />
              <CounterItem CText='120' pdescription='Happy Clients' icon='happy' />
              <CounterItem CText='7' pdescription='Years of Experience' icon='calendar' />
              {/* <CounterItem CText='24/7' pdescription='Avaialable' icon='clock' /> */}
              <div className={`col-md-3 col-sm-6`}>
                <div className="counter_item text-center wow fadeInUp" data-wow-delay="0.1s">
                  <i className={`icon-clock`}></i>
                  <h3 className="t_color">24/7</h3>
                  <p>Available</p>
                </div>
              </div>
              
            </div>
          </Reveal>
        </div>
      </section>
    )
  }
}
export default Skill;