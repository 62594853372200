import React from 'react';
import TestimonialSlider from './TestimonialSlider';
import SectionTitleTwo from '../Banner/SectionTitleTwo';

const TestimonialWebToApp = () => {
  return (
    <section id="testimonial" style={{ paddingTop: 20, paddingBottom: 0, backgroundColor:'white' }}>
      <div className="">
        <div className="testimonial_area">
          <TestimonialSlider />
        </div>
      </div>
    </section>
  )
}
export default TestimonialWebToApp;