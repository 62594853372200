import React, {  } from 'react';
import NavbarShopifyToApp from './Navbar/NavbarShopifyToApp';
import ShopifyToAppBanner from '../component/Banner/ShopifyToAppBanner';
import FooterTwo from '../component/FooterTwo';
import jhonData from '../component/jhonData';

const ShopifyToApp = () => {
    return (
        <div className="body_wrapper">
            <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
            <ShopifyToAppBanner jhonData={jhonData} />
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default ShopifyToApp
