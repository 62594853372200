import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
/*-----pages-----*/
import { Home } from "./Home";
import { ProjectView } from "./ProjectView";
import { Bid } from "./bid";
import { Home2 } from "./Home2";
import { Home3 } from "./Home3";
import { Home4 } from "./Home4";
import { Home5 } from "./Home5";
import { Home6 } from "./Home6";
import { Homefrelencer } from "./Home-frelencer";
import { NotFound } from "./404";
import AddProject from './component/add-project';
import Projects from './component/projects';
import WebToApp from './component/WebToApp';
import ShopifyToApp from './component/ShopifyToApp';
import Step1 from './component/Shopify/Step1';
import Step2 from './component/Shopify/Step2';
import Step3 from './component/Shopify/Step3';
import Plans from './component/Shopify/Plans';
import Payment from './component/Shopify/Payment';
import Success from './component/Shopify/Success';
import Support from './component/support';
import ShopifyOrders from './component/shopify-orders';
import Scraper from './component/Scraper/Scraper';
import ScraperView from './component/Scraper/ScraperView';

import { Notifications } from 'react-push-notification';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootStore from './redux/RootStore';
import { Provider } from 'react-redux';

class App extends Component {
  render() {
    return (
      <Provider store={RootStore}>
        <React.Fragment>
          <Notifications />
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/project/:param" component={ProjectView} element={<ProjectView />} />
              <Route path="/Home2" component={Home2} element={<Home2 />} />
              <Route path="/Home3" component={Home3} element={<Home3 />} />
              <Route path="/Home4" component={Home4} element={<Home4 />} />
              <Route path="/Home5" component={Home5} element={<Home5 />} />
              <Route path="/Home6" component={Home6} element={<Home6 />} />
              <Route path="/Home-frelencer" component={Homefrelencer} element={<Homefrelencer />} />
              <Route path='/add-project001' component={AddProject} element={<AddProject />} />
              <Route path='/projects001' component={Projects} element={<Projects />} />
              <Route path='/manage/bids/:param' component={Bid} element={<Bid />} />
              <Route path='/web-to-app' component={WebToApp} element={<WebToApp />} />
              <Route component={NotFound} element={<NotFound />} />
              <Route path='/shopify-to-app' component={ShopifyToApp} element={<ShopifyToApp />} />
              <Route path='/shopify-step1' component={Step1} element={<Step1 />} />
              <Route path='/shopify-step2' component={Step2} element={<Step2 />} />
              <Route path='/shopify-step3' component={Step3} element={<Step3 />} />
              <Route path='/shopify-plans' component={Plans} element={<Plans />} />
              <Route path='/shopify-payment' component={Payment} element={<Payment />} />
              <Route path='/shopify-success' component={Success} element={<Success />} />
              <Route path='/customer-support' component={Support} element={<Support />} />
              <Route path='/shopify-orders001' component={ShopifyOrders} element={<ShopifyOrders />} />
              <Route path='/scraper' component={Scraper} element={<Scraper />} />
              <Route path='/scraper-view' component={ScraperView} element={<ScraperView />} />
            </Routes>
          </Router>
          <ToastContainer />
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
