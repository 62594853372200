import React from 'react';

export const getMobileView = (url, cat) => {
    if (cat === 'iOS') {
        return (
            <div style={{ marginTop: 3 }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 0 }}>
                    <div style={{ backgroundColor: 'black', width: 30, height: 3, marginBottom: -3 }} />
                </div>
                <div className='mobile-shadow-top' style={{ marginTop: -16, backgroundColor: '#d6d8d3', width: '100%', height: 30, borderTopRightRadius: 30, borderTopLeftRadius: 30, marginBottom: 0 }} />
                <a href="./">
                    <div className='mobile-shadow mobile-frame-height' style={{ backgroundColor: '#d6d8d3', paddingLeft: 8, paddingRight: 8, paddingTop: 3, paddingBottom: 2, marginTop: 0, marginBottom: 0 }}>
                        <div className='screen-shadow mobile-frame-height' style={{ backgroundColor: 'black' }}>
                            <img src={url} style={{ backgroundColor: 'black' }} alt="" />
                        </div>
                    </div>
                </a>
                <div className='mobile-shadow-bottom' style={{ backgroundColor: '#d6d8d3', width: '100%', height: 40, borderBottomRightRadius: 30, borderBottomLeftRadius: 30 }} />
                <div style={{ marginTop: -34, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='ios-frame-menu-btn' style={{ backgroundColor: '#d6d8d3', width: 28, height: 28, borderRadius: 15, border: true, borderColor: '#848683' }} />
                </div>
                {/* <div style={{height:0}}></div> */}
            </div>
        );
    }

    if (cat === 'Android') {
        return (
            <div style={{ marginTop: 3 }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 0 }}>
                    <div style={{ backgroundColor: 'lightgrey', width: 30, height: 3, marginBottom: -3 }} />
                </div>
                <div className='mobile-shadow-top' style={{ marginTop: -8, backgroundColor: 'black', width: '100%', height: 20, borderTopRightRadius: 30, borderTopLeftRadius: 30, marginBottom: 0 }} />
                <a href="./">
                    <div className='mobile-shadow-android mobile-frame-height' style={{ backgroundColor: 'black', paddingLeft: 8, paddingRight: 8, paddingTop: 0, paddingBottom: 2, marginTop: 0, marginBottom: 0 }}>
                        <div className='mobile-frame-height' style={{ backgroundColor: 'black' }}>
                            <img src={url} style={{ backgroundColor: 'black' }} alt="" />
                        </div>
                    </div>
                </a>
                <div className='mobile-shadow-bottom' style={{ backgroundColor: 'black', width: '100%', height: 20, borderBottomRightRadius: 30, borderBottomLeftRadius: 30 }} />
                {/* <div style={{height:3}}></div> */}
                {/* <div style={{ marginTop: -17, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='ios-frame-menu-btn' style={{ backgroundColor: 'white', width: 28, height: 28, borderRadius: 15, border: true, borderColor: '#848683' }} />
            </div> */}
            </div>
        );
    }

    return (
        <div className='' style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <a href="./" style={{ padding: 3, borderRadius: 5, width: '100%' }}>
                <img src={url} style={{ width: '100%', backgroundColor:'#f1f1f1', alignSelf: 'center' }} alt="" />
            </a>
            <div style={{display:'flex'}}>
                <img style={{ width: 25, height: 25 }} src={require('./image/apple-logo.png')} />
                <img style={{ marginLeft:8, width: 25, height: 25 }} src={require('./image/android-logo.png')} />
            </div>
        </div>
    );

    // return (
    //     <div style={{ height: '70vh', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
    //         <a href="./" style={{ padding: 5, backgroundColor: '#000', borderRadius: 5, width: '70%' }}>
    //             <img src={url} style={{ width: '100%', alignSelf: 'center' }} alt="" />
    //         </a>
    //     </div>
    // );
};