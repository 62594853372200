import React, {useState, useEffect} from 'react';
import NavbarWebToApp from './Navbar/NavbarWebToApp';
import WebToAppBanner from '../component/Banner/WebToAppBanner';
import About from '../component/About';
import Service from '../component/Service';
import PortfolioSlider from '../component/Portfolio/PortfolioSlider';
import Testimonial from '../component/Testimonial/Testimonial';
import ContactTwo from '../component/ContactTwo';
import Skill from '../component/Skill/Skill';
import FooterTwo from '../component/FooterTwo';
import jhonData from '../component/jhonData';
import db, { storage } from '../firebase/firebase';

const WebToApp = () => {

  return (
    <div className="body_wrapper">
      <NavbarWebToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
      <WebToAppBanner jhonData={jhonData} />
      
      <FooterTwo jhonData={jhonData} />
    </div>
  )
}

export default WebToApp
