import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Lottie from 'react-lottie';
import anim1 from '../Lottie/anim1.json';
import anim2 from '../Lottie/anim2.json';
import anim4 from '../Lottie/anim4.json';
import anim5 from '../Lottie/anim5.json';
import anim6 from '../Lottie/anim6.json';
import anim7 from '../Lottie/anim7.json';
import anim8 from '../Lottie/anim8.json';
import anim10 from '../Lottie/anim10.json';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import GLOBAL from '../Global'

const timeouts = [
    '1 Second',
    '2 Seconds',
    '3 Seconds',
]
const loaders = [
    'Hide',
    'Style 1',
    'Style 2',
    'Style 3',
    'Style 4',
    'Style 5',
    'Style 6',
    'Style 7',
    'Style 8',
]

const Step2 = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    let profilePercentage = 50
    let mobileWidth = 175
    let mobileHeight = 300

    const [color, setColor] = useState('#ffffff');
    const [width, setWidth] = useState(33);
    const handleWidthChange = (event, newValue) => {
        console.log(newValue);
        setWidth(newValue);
    };

    const addFile = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [logoUrl, setLogoUrl] = useState('');
    const [logoFile, setLogoFile] = useState('');
    const [timeout, setTimeout] = React.useState('2 Seconds');
    const [loader, setLoader] = React.useState('Style 2');

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step2) {
                setLogoUrl(shopifyData.step2.logoUrl)
                setWidth(shopifyData.step2.logoSize)
                setColor(shopifyData.step2.backgroundColor)
                setTimeout(shopifyData.step2.timeout)
                setLoader(shopifyData.step2.loader)
            }
        }
    }, [])

    const onFilesSelect = (event) => {
        let files = Array.from(event.target.files)
        setSelectedFiles(files)
        setLogoUrl(URL.createObjectURL(files[0]))
        setLogoFile(files[0])
        GLOBAL.shopifyLogo = files[0]
    };

    const updateValue = (key, value) => {
        //
    };

    const getAnim = () => {
        if (loader === loaders[1])
            return anim1
        else if (loader === loaders[2])
            return anim2
        else if (loader === loaders[3])
            return anim4
        else if (loader === loaders[4])
            return anim5
        else if (loader === loaders[5])
            return anim6
        else if (loader === loaders[6])
            return anim7
        else if (loader === loaders[7])
            return anim8
        else if (loader === loaders[8])
            return anim10
    };

    const getLogoWidth = () => {
        let w = 175 * ((width / 100))
        console.log('w: ' + w);
        return w
    };

    const getAnimSizeMargin = () => {
        if (loader === loaders[1])
            return { size: 50, marginTop: 10 }
        else if (loader === loaders[2])
            return { size: 60, marginTop: 0 }
        else if (loader === loaders[3])
            return { size: 50, marginTop: 10 }
        else if (loader === loaders[4])
            return { size: 35, marginTop: 25 }
        else if (loader === loaders[5])
            return { size: 40, marginTop: 20 }
        else if (loader === loaders[6])
            return { size: 40, marginTop: 20 }
        else if (loader === loaders[7])
            return { size: 35, marginTop: 25 }
        else if (loader === loaders[8])
            return { size: 50, marginTop: 10 }
        return 0
    };

    return (
        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>

            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={() => {
                    window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store to a Mobile App.&phone=+923074052841", '_blank')
                }}>
                <img
                    src={require('../../image/wa1.png')}
                    style={{ alignSelf: 'center', width: 35, height: 35, tintColor: '#04B486', marginTop: -3 }}
                    alt=""
                />
            </button>

            <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
            <div style={{ display: 'flex', marginTop: 100, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ width: 70, height: 70 }}
                    src={require('../../image/shopify.png')} />
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginTop: 30, marginLeft: 10, color: '#113E49' }}>
                    Mobile App Builder
                </h3>
            </div>
            <div className='shopify-step-parent' style={{ overflow: 'scroll', paddingBottom: 80, display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div style={{ height: 8, width: '100%', backgroundColor: '#f2f2f2', marginTop: 50, borderRadius: 4 }}>
                    <div style={{ height: 8, backgroundColor: '#0db3c7', width: profilePercentage + '%', borderRadius: 4 }} />
                </div>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>

                    <div style={{ display: 'flex', paddingRight: 10, flexWrap: 'wrap', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', minWidth: 300, marginRight: 10 }}>
                            <div style={{ display: 'flex', marginTop: 15, flexDirection: 'column', alignSelf: 'center', flex: 1, justifyContent: 'center' }}>
                                <div
                                    style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>
                                    Customize Splash Screen
                                </div>
                                <div style={{ display: 'flex', marginTop: 0, height: 45, alignItems: 'center' }}>
                                    <button
                                        onClick={() => {
                                            addFile.current.click();
                                        }}
                                        style={{ fontSize: 16, marginTop: 20, paddingLeft: 15, paddingRight: 15, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: '#0db3c7', borderRadius: 5 }}>
                                        {logoUrl !== '' ?
                                            'Change Logo'
                                            :
                                            'Upload App Logo (Optional)'
                                        }
                                    </button>
                                    <input
                                        type='file'
                                        multiple="multiple"
                                        style={{ display: 'none' }}
                                        ref={addFile}
                                        onChange={(event) => {
                                            onFilesSelect(event)
                                        }} />
                                </div>
                                <div style={{ fontSize: 14, marginTop: 15, marginRight: 10, lineHeight: 1.5 }}>
                                    <span style={{ color: 'orange' }}>Note:</span> If you do not provide logo, we will use your store logo by default.
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, padding: 0, backgroundColor: '#fff', border: '0px solid lightgray', marginRight: 20, borderRadius: 5 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{}}>Logo Size: </div>
                                        <Stack spacing={2} direction="row" style={{ flex: 1, marginTop: 1, marginLeft: 50 }} alignItems="center">
                                            <Slider aria-label="width" value={width} onChange={handleWidthChange} />
                                        </Stack>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
                                        <div style={{ marginTop: 6, marginRight: 20 }}>Background Color: </div>
                                        <input
                                            type="color"
                                            value={color}
                                            onChange={(event) => {
                                                setColor(event.target.value)
                                            }}
                                            style={{ border: '0px solid green', width: 160, height: 45 }} />
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                                        <div style={{ marginRight: 20 }}>Screen Timeout: </div>
                                        <select
                                            name="timeout"
                                            id="timeout"
                                            value={timeout}
                                            style={{ height: 45, border: '0px', minWidth: 160, paddingLeft: 10 }}
                                            onChange={(event) => {
                                                setTimeout(event.target.value)
                                            }}>
                                            {timeouts.map((item, index) => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </select>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                                        <div style={{ marginRight: 20 }}>Loading Indicator: </div>
                                        <select
                                            name="loader"
                                            id="loader"
                                            value={loader}
                                            style={{ height: 45, border: '0px', minWidth: 160, paddingLeft: 10 }}
                                            onChange={(event) => {
                                                setLoader(event.target.value)
                                            }}>
                                            {loaders.map((item, index) => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 20, borderRadius: 11, backgroundColor: 'black', paddingLeft: 3, paddingRight: 3, paddingTop: 3, paddingBottom: 10 }}>
                            <div style={{ width: 25, height: 3, backgroundColor: 'lightgray', marginTop: 5, marginBottom: 5 }} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', width: mobileWidth, height: mobileHeight, borderRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, backgroundColor: color }}>
                                {loader !== 'Hide' &&
                                    <div style={{ height: 30 }} />
                                }
                                {logoUrl !== '' ?
                                    <img src={logoUrl} style={{ width: getLogoWidth(), alignSelf: 'center', height: getLogoWidth(), objectFit: 'contain' }} />
                                    :
                                    <img src={require('../../image/shopify.png')} style={{ width: getLogoWidth(), alignSelf: 'center', height: getLogoWidth(), objectFit: 'contain' }} />
                                }
                                {loader !== 'Hide' &&
                                    <div style={{ marginTop: getAnimSizeMargin().marginTop }}>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: getAnim(),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={getAnimSizeMargin().size}
                                            width={getAnimSizeMargin().size}
                                        />
                                    </div>
                                }

                            </div>
                            <div style={{ width: 25, height: 25, backgroundColor: 'lightgray', marginTop: 10, borderRadius: 15 }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', minWidth: 280, flex: 1, flexDirection: 'column' }}>
                        <Link
                            onClick={() => {
                                console.log('click');
                                let data = {
                                    logoUrl: logoUrl,
                                    logoSize: width,
                                    backgroundColor: color,
                                    timeout: timeout,
                                    loader: loader,
                                }
                                let shopifyDataTemp = { ...shopifyData }
                                shopifyDataTemp['step2'] = data
                                dispatch(Actions.setShopifyData(shopifyDataTemp))
                                navigate('/shopify-step3', { replace: false });
                            }}
                            className='btn my-button' style={{ height: 55, backgroundColor: '#0db3c7', color: 'white', marginTop: 20, width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                            Continue
                        </Link>
                    </div>
                </div>
            </div>
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default Step2; 