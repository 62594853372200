import React, { Component } from 'react';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import TestimonialSliderTwo from './TestimonialSliderTwo';

class TestimonialTwo extends Component {
    render() {
        return (
            <div className="testimonial_area_two" style={{ paddingTop: 50, paddingBottom: 50 }} id="testimonial">
                <div className="container">
                    <div className="testimonial_slider_info">
                        <SectionTitleTwo tCenter="text-center" stitle="Customer feedback" btitle="What our customers say" />
                        <TestimonialSliderTwo />
                    </div>
                </div>
            </div>
        )
    }
}

export default TestimonialTwo;