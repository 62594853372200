import React, { useState, useEffect } from 'react';
import FooterTwo from './component/FooterTwo';
import jhonData from './component/jhonData';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';
// import * as Loader from "react-loader-spinner";
import { Rings } from 'react-loader-spinner';
import {
  Link,
  useParams
} from "react-router-dom";
import db, { storage } from './firebase/firebase';
import { getMobileView } from './mobile_view';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const ProjectView = () => {
  let { param } = useParams();
  console.log('project: ' + param);
  const cat = param.split('_')[0];
  const id = param.split('_')[1];

  const [model, setModel] = useState(null);
  const [iconUrl, setIconUrl] = useState(null);
  const [screenshots, setScreenshots] = useState([]);
  const [loader, setLoader] = useState(false);

  const getImageUrl = (path, onSuccess) => {
    storage.ref().child(path).getDownloadURL().then((url) => {
      onSuccess(url);
    })
  }

  const loadData = () => {
    console.log('loadData');
    setLoader(true);
    console.log('cat: '+cat.slice(0, 1).toUpperCase() + cat.slice(1, cat.length));
    db.collection(cat)
      .doc(id)
      .get()
      .then(doc => {
        setLoader(false);
        if (doc.exists) {
          console.log('exists');
          const model = doc.data();
          console.log('title: ' + model.title);
          setModel(model);
          getImageUrl(
            model.cat + '/' + model.title + '/' + model.icon,
            (url) => {
              console.log('url: ' + url);
              setIconUrl(url);
            });
          let arr = model.screenshots.split(',');

          if (arr.length === 3) {
            settings.slidesToShow = 1;
          }

          let shots = [];
          for (let i = 0; i < arr.length; i++) {
            const photoName = arr[i];
            const photoPath = model.cat + '/' + model.title + '/screenshots/' + photoName;
            shots = shots.concat({
              file: null,
              url: null,
              name: photoName,
            })
            if (i === arr.length - 1)
              setScreenshots(shots);

            getImageUrl(
              photoPath,
              (url) => {
                shots[i].url = url;
                setScreenshots([...shots], shots);
              });
          }
        }
        else {
          console.log('not exist');
        }
      });
  };

  useEffect(() => {
    console.log('useEffect');
    loadData();
  }, []);

  return (
    <div className='body_wrapper column'>
      <div style={{ backgroundColor: '#fafafa' }}>
        <div className={`container`}>
          <a className="navbar-brand logo_h" href="/">
            <img src={require("./image/logo-1.png")} style={{ height: 40 }} alt="" />
          </a>
        </div>
      </div>

      <div style={{ backgroundColor: '#fff' }}>
        <div className="container" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='column' style={{ paddingTop: 30, paddingBottom: 10 }}>
            <div className='my-row row-center-v flex-wrap' style={{ justifyContent: 'space-between' }}>

              <div className='my-row row-center-v' style={{ marginBottom: 20 }}>

                {iconUrl === null ?
                  <div className='app-icon' />
                  :
                  <img className='app-icon' src={iconUrl} />
                }

                <Fade top cascade duration={700}>
                  <div className='column col-center-v ml-30'>
                    <h3>{model !== null ? model.title : 'Loading...'}</h3>
                    <p style={{ fontSize: 18, color: '#20b4c6' }}>{model !== null ? model.cat : 'Loading...'}</p>
                    {model !== null ?
                      model.url !== '' ?
                        <a href={model.url}>
                          <button
                            onClick={() => { }}
                            type="button"
                            class='btn btn-info'
                            style={{ backgroundColor: 'white', color: '#1a81b2' }}>
                            View Live on Store
                          </button>
                        </a>
                        :
                        null
                      :
                      null
                    }
                  </div>
                </Fade>
              </div>

              <div className='column col-middle-right' style={{ alignSelf: 'flex-end', minWidth: 300, marginBottom: 20 }}>
                <Fade right cascade duration={1000}>
                  <div className="column">
                    <div>
                      <div className="work_item fadeInUp column col-center-v" data-wow-delay="0.1s" style={{ height: 40, borderRadius: 5, }}>
                        <div style={{ fontSize: 12, textAlign: 'left', marginTop: -12 }}>Developed By:</div>
                        <div className="t_color" style={{ color: '#20b4c6', marginTop: -5, textAlign: 'left' }}>{model !== null ? model.devBy : 'Loading...'}</div>
                      </div>
                    </div>
                    <div style={{ marginTop: -20, marginBottom: -30 }}>
                      <div className="work_item fadeInUp column col-center-v" data-wow-delay="0.1s" style={{ height: 40, borderRadius: 5 }}>
                        <div style={{ fontSize: 12, textAlign: 'left', marginTop: -12, }}>Tools & Technologies:</div>
                        <div className="t_color" style={{ color: '#20b4c6', marginTop: -5, textAlign: 'left' }}>{model !== null ? model.tech : 'Loading...'}</div>
                      </div>
                    </div>
                    {/* <div>
                      <div className="work_item fadeInUp my-row row-center-v" data-wow-delay="0.1s" style={{ height: 40, borderRadius: 5,}}>
                        <p style={{ fontSize: 15, marginRight: 10, textAlign:'left' }}>Developed By:</p>
                        <p className="t_color" style={{ color: '#20b4c6', textAlign:'left'}}>{model !== null ? model.devBy : 'Loading...'}</p>
                      </div>
                    </div>
                    <div style={{ marginTop: -20, marginBottom: -30 }}>
                      <div className="work_item fadeInUp my-row row-center-v" data-wow-delay="0.1s" style={{ height: 40, borderRadius: 5,}}>
                        <p style={{ fontSize: 15, marginRight: 10, textAlign:'left' }}>Tools & Technologies:</p>
                        <p className="t_color" style={{ color: '#20b4c6', textAlign:'left' }}>{model !== null ? model.tech : 'Loading...'}</p>
                      </div>
                    </div> */}
                  </div>
                </Fade>
              </div>

            </div>
          </div>
        </div>
      </div>

      <section className="portfolio_slider_area" style={{ paddingTop: 30, paddingBottom: 30, backgroundColor: '#fafafa' }} id="portfolio">
        <div className="container">
          {loader ?
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
              <Rings
                type="ThreeDots"
                color="#20b4c6"
                height={70}
                width={70}
                visible={true} />
            </div>
            :
            <Slider {...settings} className="portfolio_slider" onClick={() => { }}>
              {
                screenshots.map((shot, index) => {
                  return (
                    <Link to='#'>
                      <Fade bottom cascade duration={500}>
                        <div className="p_item" style={{marginBottom:0}}>
                          <div className="portfolio_content">
                            {getMobileView(shot.url, model.cat)}
                          </div>
                        </div>
                      </Fade>
                    </Link>
                  )
                })
              }
            </Slider>
          }
        </div>
      </section>

      <div style={{ backgroundColor: '#fff', paddingTop: 40, paddingBottom: 20 }}>
        <div className={`container column col-center-h`} style={{ alignSelf: 'center', paddingLeft: 50, paddingRight: 50 }}>
          <p className='t_color' style={{ fontSize: 20 }}>{model !== null ? model.desc : 'Loading...'}</p>
        </div>
      </div>

      <FooterTwo jhonData={jhonData} />
    </div>
  );
}

