import React, {useState, useEffect} from 'react';
import NavbarTwo from './component/Navbar/NavbarTwo';
import FrelencerBanner from './component/Banner/frelencerBanner';
import About from './component/About';
import Service from './component/Service';
import PortfolioSlider from './component/Portfolio/PortfolioSlider';
import Testimonial from './component/Testimonial/Testimonial';
import ContactTwo from './component/ContactTwo';
import Skill from './component/Skill/Skill';
import FooterTwo from './component/FooterTwo';
import jhonData from './component/jhonData';
import db, { storage } from './firebase/firebase';

// theme color = #20b4c6

export const Home = () => {

  return (
    <div className="body_wrapper">
      <NavbarTwo mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
      <FrelencerBanner jhonData={jhonData} />
      <Service wClass="work_area" jhonData={jhonData} />
      <PortfolioSlider/>
      <Skill />
      <Testimonial />
      {/* <About aClass="about_area" jhonData={jhonData} /> */}
      <ContactTwo jhonData={jhonData} />
      <FooterTwo jhonData={jhonData} />
    </div>
  )
}

