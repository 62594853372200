import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const WebToAppFAQs = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Typography>
                        What do I need to create my app?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: 'gray' }}>
                        You just need your website URL, App Name and Logo.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header">
                    <Typography>
                        Is Apple Developer account required for iOS app?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'gray' }}>
                        Yes, Apple Developer account is required to generate an ios app that can be installed on physical device or published on app store.</Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header">
                    <Typography>
                        When will my application be ready?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'gray' }}>
                        App will be ready in few hours. It might take 1 business day in some busy days.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header">
                    <Typography>
                        What if I make changes in Website?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'gray' }}>
                        Your app will always be in sync with your website so whatever changes you make it will automatically reflect in the app.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header">
                    <Typography>
                        How will I receive my application?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'gray' }}>
                        You will get release-able builds for the apps which can be published on app stores. You will be notified on whatsapp/email when your app is ready.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header">
                    <Typography>
                        Can I publish the app on App store and Google play?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'gray' }}>
                        Yes, absolutely. Once you receive your app, you can publish it without any problem. It is an easy process. We also provide publishing services with a little extra charges.
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}

export default WebToAppFAQs