import React, { useState, useEffect } from 'react';
import '../App.css';
import db from '../firebase/firebase';
import moment from 'moment';

const ShopifyOrders = () => {
    const [models, setModels] = useState([]);

    const loadData = () => {
        db.collection('shopifyOrders')
            .orderBy('added_date', 'desc')
            .get()
            .then(querySnapshot => {
                const orders = querySnapshot.docs.map(doc => doc.data());
                setModels(orders)
            });
    };

    // load data
    useEffect(() => {
        loadData();
    }, []);


    return (
        <div className='main'>
            <div className='titlebar'>
                <div>
                    Shopify Orders
                </div>
            </div>

            {models.map((model, index) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 15, paddingTop:15, paddingBottom:15, paddingRight: 15, marginTop: 10, border: '1px solid gray' }}>
                        <div>
                            Date: {moment(parseInt(model.added_date)).format('DD-MM-YYYY h:m a')}
                        </div>
                        <div style={{ color: 'blue' }}>
                            <a href={model.logo} target='_blank'>Logo Download</a>
                        </div>
                        <div>
                            Store Url: <span style={{ color: 'blue' }}>{model.step1.url}</span>
                        </div>
                        <div>
                            Platform: <span style={{ color: 'blue' }}>{model.step1.platform}</span>
                        </div>
                        <div>
                            Email: <span style={{ color: 'blue' }}>{model.step1.email}</span>
                        </div>
                        <div>
                            Whatsapp: <span style={{ color: 'blue' }}>{model.step1.whatsapp}</span>
                        </div>
                        <div>
                            Splash Background: <span style={{ color: 'blue' }}>{model.step2.backgroundColor}</span>
                        </div>
                        <div>
                            Splash Loader: <span style={{ color: 'blue' }}>{model.step2.loader}</span>
                        </div>
                        <div>
                            Logo Size: <span style={{ color: 'blue' }}>{model.step2.logoSize}</span>
                        </div>
                        <div>
                            Splash Timeout: <span style={{ color: 'blue' }}>{model.step2.timeout}</span>
                        </div>
                        <div>
                            Icon Tint: <span style={{ color: 'blue' }}>{model.step3.iconTint}</span>
                        </div>
                        <div>
                            Page Loader: <span style={{ color: 'blue' }}>{model.step3.loader}</span>
                        </div>
                        <div>
                            Show Tab Titles: <span style={{ color: 'blue' }}>{model.step3.showTitles ? 'Yes' : 'No'}</span>
                        </div>
                        <div>
                            Tabar Color: <span style={{ color: 'blue' }}>{model.step3.tabBarColor}</span>
                        </div>
                        <div>
                            Payment Method: <span style={{ color: 'blue' }}>{model.paymentMethod}</span>
                        </div>
                    </div>
                );
            })}

        </div>
    );
}

export default ShopifyOrders;
