import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import showToast from '../toast';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import { Form, FormGroup, Button, Input } from 'reactstrap';
import db from '../../firebase/firebase';
import moment from 'moment/moment';
// const { getJson } = require("serpapi");

const ScraperView = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const [keyword, setKeyword] = useState('beauty');
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchesOpened, setSearchesOpened] = useState('');

    useEffect(() => {
        loadHistory()
        loadSearchesOpened()
    }, [])

    const loadSearchesOpened = async () => {
        let a = await db.collection('searchesOpened').doc('searchesOpened').get()
        let searchesOpened = ''
        if (a.exists) {
            searchesOpened = a.data().searchesOpened
            setSearchesOpened(searchesOpened)
        }
    };

    const loadHistory = () => {
        db.collection('shopifySearches')
            .get()
            .then(res => {
                let arr = []
                for (let i = 0; i < res.docs.length; i++) {
                    const doc = res.docs[i];
                    let obj = doc.data()
                    obj.id = doc.id
                    if (obj.json.dmca_messages) {
                        // skip
                        continue
                    }
                    arr.push(obj)
                }
                arr.sort((a, b) => a.date - b.date)
                setPages(arr)
            });
    }

    const setItemOpened = async (item, item_, index) => {
        let page = pages[currentPage]
        let json = page.json
        let organic_results = json.organic_results
        organic_results[index].opened = true
        json.organic_results = organic_results
        page.json = json
        let pages_ = [...pages]
        pages_[index] = page
        setPages(pages_)
        db.collection('shopifySearches')
            .doc(item.id)
            .set({
                json: json,
            }, { merge: true })

        window.open(item_.link, '_blank')

        // check if source is same
        let a = await db.collection('searchesOpened').doc('searchesOpened').get()
        let searchesOpened = ''
        let alreadyAdded = false
        if (a.exists) {
            searchesOpened = a.data().searchesOpened
            if (searchesOpened.includes(item_.source + ',')) {
                alreadyAdded = true
            }
        }
        if (!alreadyAdded) {
            db.collection('searchesOpened')
                .doc('searchesOpened')
                .set({
                    searchesOpened: searchesOpened + item_.source + ',',
                }, { merge: true })
            // save locally
            setSearchesOpened(searchesOpened + item_.source + ',')
        }
    }

    const getDomain = (url, subdomain) => {
        subdomain = subdomain || false;
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
        if (!subdomain) {
            url = url.split('.');
            url = url.slice(url.length - 2).join('.');
        }
        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }
        return url;
    }

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', marginTop: 30, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginLeft: 10, color: '#113E49' }}>
                    Shopify Stores
                </h3>
            </div>
            <div style={{ width: '80%', height: '80vh', display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch', overflow: 'scroll' }}>

                {pages.length > 0 &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {pages[currentPage].json.organic_results.map((item, index) => {
                            if (item.link.includes('.myshopify.com')
                                || item.link.includes('.shopify.com')
                                || item.title.toLowerCase().includes('shopify themes')
                                || item.title.toLowerCase().includes('shopify brands')
                                || (item.title.toLowerCase().includes('shopify')) && (item.title.toLowerCase().includes('stores'))) {
                                return null
                            }

                            let opened = item.opened || searchesOpened.includes(item.source + ',')

                            console.log('id: ' + pages[currentPage].id);

                            return (
                                <button
                                    key={index}
                                    style={{ backgroundColor: opened ? '#f2f2f2' : 'transparent', marginBottom: 5, marginRight: 10, padding: 10, border: '1px solid gray', borderRadius: 5, display: 'flex', }}
                                    onClick={() => {
                                        setItemOpened(pages[currentPage], item, index)
                                        // window.open(item.json.search_metadata.raw_html_file, '_blank')
                                    }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={item.favicon} style={{ backgroundColor: 'lightgray', width: 25, height: 25, borderRadius: 20 }} />
                                            <div style={{ marginLeft: 5, color: 'black', fontSize: 12 }}>
                                                {item.source}
                                            </div>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ color: 'black', fontSize: 16, fontWeight: 'bold' }}>
                                                {item.title}
                                            </div>
                                            <button
                                                style={{ backgroundColor: '#0489B1', color: 'white', fontSize: 16, width: 120, border: '0px solid blue', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                onClick={() => {
                                                    let domain = getDomain(item.link)
                                                    let url = 'https://www.google.com/search?q='+domain+' ceo or owner linkedin'
                                                    window.open(url, '_blank')
                                                }}>
                                                View Owner
                                            </button>
                                        </div>
                                        <div style={{ color: '#0489B1', fontSize: 14 }}>
                                            {item.link}
                                        </div>
                                        <div style={{ color: 'black', fontSize: 14 }}>
                                            {item.snippet}
                                        </div>
                                    </div>
                                </button>
                            )
                        })}
                    </div>
                }

                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 15 }}>
                    {pages.map((item, index) => {
                        return (
                            <button key={index}
                                style={{ backgroundColor: currentPage == index ? 'black' : 'transparent', marginBottom: 5, marginRight: 10, padding: 10, border: '1px solid gray', width: 45, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => {
                                    setCurrentPage(index)
                                    // window.open(item.json.search_metadata.raw_html_file, '_blank')
                                }}>
                                <div>
                                    <div rel="noopener noreferrer" style={{ color: currentPage == index ? 'white' : 'blue' }}>
                                        {index + 1}
                                    </div>
                                </div>
                            </button>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default ScraperView;