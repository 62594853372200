import React, { Component } from 'react';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import Slider from 'react-slick';
import db, { storage } from '../../firebase/firebase';
import { Rings } from 'react-loader-spinner';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from '../../mobile_view';

class WebToAppPortfolioSlider extends Component {

    state = {
        portType: 'Android',
        photos: [
            require('../../image/web-to-app-samples/s1-portrait.png'),
            require('../../image/web-to-app-samples/s2-portrait.png'),
            require('../../image/web-to-app-samples/s3-portrait.png'),
            require('../../image/web-to-app-samples/s4-portrait.png'),
            require('../../image/web-to-app-samples/s5-portrait.png'),
        ],
        loader: true,
    }

    componentDidMount() {
        //
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: 3000,
            slidesToShow: this.state.portType === 'Website' ? 1 : 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="portfolio_slider_area" style={{ paddingTop: 0, paddingBottom: 50 }} id="portfolio">
                <div className="container">
                    <Slider {...settings} className="portfolio_slider">
                        {
                            this.state.photos.map((photo, index) => {
                                return (
                                    <Fade bottom cascade duration={500}>
                                        <div className="p_item">
                                            <div className="portfolio_content">
                                                {getMobileView(photo, '')}
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            })
                        }
                    </Slider>

                </div>
            </section>
        )
    }
}

export default WebToAppPortfolioSlider;