import React, { Component } from 'react';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';
import Fade from 'react-reveal/Fade';

class About extends Component {
    render() {
        let jhonData = this.props.jhonData;
        var { wClass } = this.props
        return (
            <section className={`${wClass}`} style={{ paddingTop: 50, paddingBottom: 50, backgroundColor: '#fafafa' }} id="about">
                <div className="container">
                    <SectionTitleTwo tCenter="text-center" stitle="Team" btitle="Introducing Our Small Team" />
                    <Fade bottom cascade duration={1000}>
                        <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="col-lg-4 col-sm-6">
                                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                                    <img className='team-img' src={require('../image/saif.jpg')} alt="" />
                                    <h2 className="t_color" style={{ marginTop: 15 }}>Saifal Nadeem</h2>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',paddingLeft:30, paddingRight:30 }}>
                                        <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: 30, height: 30, padding:3 }} src={require('../image/android1.png')} alt="" />
                                            <p style={{ fontSize: 15, marginBottom: 0, marginLeft: 10 }}>Native Android Developer</p>
                                        </div>
                                        <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: 30, height: 30, padding:1 }} src={require('../image/react.png')} alt="" />
                                            <p style={{ fontSize: 15, marginBottom: 0, marginLeft: 10 }}>React Native Developer</p>
                                        </div>
                                        <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: 30, height: 30, padding:4 }} src={require('../image/web.png')} alt="" />
                                            <p style={{ fontSize: 15, marginBottom: 0, marginLeft: 10 }}>Web UI/UX Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                                    <img className='team-img' src={require('../image/faisal.png')} alt="" />
                                    <h2 className="t_color" style={{ marginTop: 15 }}>Faisal Nadeem</h2>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',paddingLeft:30, paddingRight:30 }}>
                                        <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: 30, height: 30 }} src={require('../image/apple1.png')} alt="" />
                                            <p style={{ fontSize: 15, marginBottom: 0, marginLeft: 10 }}>Native iOS Developer</p>
                                        </div>
                                        <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: 30, height: 30, padding:3 }} src={require('../image/flutter.png')} alt="" />
                                            <p style={{ fontSize: 15, marginBottom: 0, marginLeft: 10 }}>Flutter Developer</p>
                                        </div>
                                        <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: 30, height: 30, padding:4 }} src={require('../image/web.png')} alt="" />
                                            <p style={{ fontSize: 15, marginBottom: 0, marginLeft: 10 }}>Backend Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
        )
    }
}

export default About;