import React, { Component } from 'react';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';
import Fade from 'react-reveal/Fade';

class Service extends Component {
    render() {
        let jhonData = this.props.jhonData;
        var { wClass } = this.props
        return (
            <section className={`${wClass}`} style={{ paddingTop: 50, paddingBottom: 50 }} id="service">
                <div className="container">
                    <SectionTitleTwo tCenter="text-center" stitle="Services" btitle="What We Build" />
                    <Fade bottom cascade duration={1000}>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                                    <img src={require('../image/web.png')} style={{ width: 70, height: 70, marginBottom: 20 }} alt="" />
                                    <a href=".#"><h2 className="t_color">Websites</h2></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                                    <img src={require('../image/android.png')} style={{ width: 70, height: 70, marginBottom: 20 }} alt="" />
                                    <a href=".#"><h2 className="t_color">Android Apps</h2></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                                    <img src={require('../image/apple.png')} style={{ width: 70, height: 70, marginBottom: 20 }} alt="" />
                                    <a href=".#"><h2 className="t_color">iOS Apps</h2></a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
        )
    }
}

export default Service;