import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import add from '../image/add.png';
import cross from '../image/cross.png';
import db, { storage } from '../firebase/firebase';
import showToast from './toast';
import { ToastContainer } from 'react-toastify';
import { Rings } from 'react-loader-spinner';
import { Alert } from 'reactstrap';
import { useLocation } from "react-router-dom";

const AddProject = (props) => {
  const location = useLocation();
  const model = location.state.model;
  const [order, setOrder] = useState(model ? model.order : location.state.order);
  const [cat, setCat] = useState(model ? model.cat : location.state.cat);
  const [title, setTitle] = useState(model ? model.title : '');
  const [devBy, setDevBy] = useState(model ? model.devBy : '');
  const [tech, setTech] = useState(model ? model.tech : '');
  const [desc, setDesc] = useState(model ? model.desc : '');
  const [url, setUrl] = useState(model ? model.url : '');
  const [icon, setIcon] = useState(null);
  const [iconUrl, setIconUrl] = useState(null);
  const [iconName, setIconName] = useState(null);
  const [mainShot, setMainShot] = useState(null);
  const [mainShotUrl, setMainShotUrl] = useState(null);
  const [mainShotName, setMainShotName] = useState(null);
  const [screenshots, setScreenshots] = useState([{}]);
  const [loader, setLoader] = useState(false);
  const [delAlert, setDelAlert] = useState(false);
  const addIcon = useRef(null);
  const addMainShot = useRef(null);
  const addScreenshot = useRef(null);

  const handleAddScreenshot = (event) => {
    let temp = [{}];
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let url = URL.createObjectURL(file);
      temp = temp.concat({ file: file, url: url, name: file.name });
    }
    setScreenshots(temp);
  };

  const showScreenshotsPanel = () => {
    return screenshots.map((item, index) => {
      if (index == 0) {
        return (
          <div style={{ marginRight: 10, marginBottom: 10 }}>
            <div onClick={() => {
              // browse screenshot
              addScreenshot.current.click();
            }} style={{ width: 100, height: 170, backgroundColor: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={add} style={{ width: 30, height: 30 }} />
              <input
                multiple
                type='file'
                style={{ display: 'none' }}
                ref={addScreenshot}
                onChange={handleAddScreenshot}
              />
            </div>
          </div>
        );
      }
      return (
        <div style={{ display: 'inline-flex', width: 85, height: 170, marginRight: 10, marginBottom: 10 }}>
          <img src={item.url} style={{ width: '100%', height: '100%' }} />
          <div onClick={() => {
            // delete screenshot
            const temp = screenshots.filter((item, j) => {
              return index !== j; //false will delete
            });
            setScreenshots(temp);
          }} style={{ width: 25, height: 25, marginLeft: -25, marginTop: 0, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={cross} style={{ width: 20, height: 20 }} />
          </div>
        </div>
      );
    });
  }

  const getImageUrl = (path, onSuccess) => {
    storage.ref().child(path).getDownloadURL().then((url) => {
      onSuccess(url);
    })
  }

  useEffect(() => {
    console.log('useEffect');
    if (model) {
      // edit mode
      getImageUrl(
        model.cat + '/' + model.title + '/' + model.icon,
        (url) => {
          console.log('url: ' + url);
          setIconUrl(url);
          setIconName(model.icon);
        });
      getImageUrl(
        model.cat + '/' + model.title + '/' + model.mainShot,
        (url) => {
          console.log('url: ' + url);
          setMainShotUrl(url);
          setMainShotName(model.mainShot);
        });
      let arr = model.screenshots.split(',');
      let shots = [{}];
      for (let i = 0; i < arr.length; i++) {
        const photoName = arr[i];
        const photoPath = model.cat + '/' + model.title + '/screenshots/' + photoName;
        shots = shots.concat({
          file: null,
          url: null,
          name: photoName,
        })
        if (i === arr.length - 1)
          setScreenshots(shots);

        getImageUrl(
          photoPath,
          (url) => {
            shots[i + 1].url = url;
            setScreenshots([...shots], shots);
          });
      }
    }
    else {
      // add mode
      if (icon !== null)
        setIconUrl(URL.createObjectURL(icon));
      if (mainShot !== null)
        setMainShotUrl(URL.createObjectURL(mainShot));
    }
  }, []);

  const addProject = () => {
    console.log('addProject');
    if (icon === null || mainShot === null || screenshots.length === 1)
      return;

    let photoNames = screenshots[1].file.name;
    for (let i = 2; i < screenshots.length; i++) {
      const name = screenshots[i].file.name;
      photoNames = photoNames + ',' + name;
    }

    let project = {
      order: order,
      cat: cat,
      title: title,
      devBy: devBy,
      tech: tech,
      url: url,
      desc: desc,
      icon: icon.name,
      mainShot: mainShot.name,
      screenshots: photoNames,
    };

    console.log('add icon');
    setLoader(true);
    uploadPhoto(
      project.cat + '/' + project.title + '/' + icon.name,
      icon,
      () => {
        console.log('add mainShot');
        uploadPhoto(
          project.cat + '/' + project.title + '/' + mainShot.name,
          mainShot,
          () => {
            console.log('add screenshots');
            for (let i = 1; i < screenshots.length; i++) {
              const screenshot = screenshots[i];
              uploadPhoto(
                project.cat + '/' + project.title + '/screenshots/' + screenshot.file.name,
                screenshot.file,
                () => {
                  console.log('screenshot added');
                  if (i === screenshots.length - 1) {
                    console.log('add info');
                    db.collection(project.cat).doc(project.title)
                      .set(project, { merge: true })
                      .then(() => {
                        setLoader(false);
                        showToast('success', 'Project Saved!')
                      })
                      .catch(error => {
                        showToast('error', 'Save Failed!');
                      });
                  }
                }
              );
            }
          }
        );
      }
    );
  };

  const updateProject = () => {
    console.log('updateProject');

    let project = {
      order: order,
      cat: cat,
      title: title,
      devBy: devBy,
      tech: tech,
      url: url,
      desc: desc,
    };

    setLoader(true);

    // update info
    db.collection(project.cat).doc(project.title)
      .set(project, { merge: true })
      .then(() => {
        // wait a bit to upload images
        setTimeout(() => {
          setLoader(false);
          showToast('success', 'Project Saved!')
        }, 3000);
      })
      .catch(error => {
        showToast('error', 'Save Failed!');
      });

    // update icon
    if (icon !== null) {
      console.log('update icon');
      uploadPhoto(
        project.cat + '/' + project.title + '/' + icon.name,
        icon,
        () => {
          // update icon info
          db.collection(project.cat).doc(project.title).set({ icon: icon.name }, { merge: true });
        }
      );
    }

    // update main shot
    if (mainShot !== null) {
      console.log('update main shot');
      uploadPhoto(
        project.cat + '/' + project.title + '/' + mainShot.name,
        mainShot,
        () => {
          // update main shot info
          db.collection(project.cat).doc(project.title).set({ mainShot: mainShot.name }, { merge: true });
        }
      );
    }

    // update screenshots
    for (let i = 1; i < screenshots.length; i++) {
      const screenshot = screenshots[i];
      if (screenshot.file === null) {
        console.log('screenshot already uploaded');
      }
      else {
        uploadPhoto(
          project.cat + '/' + project.title + '/screenshots/' + screenshot.file.name,
          screenshot.file,
          () => {
            console.log('screenshot added');
          }
        );
      }
    }

    // update screenshots info
    if (screenshots.length > 1) {
      let photoNames = screenshots[1].name;
      for (let i = 2; i < screenshots.length; i++) {
        const name = screenshots[i].name;
        photoNames = photoNames + ',' + name;
      }
      db.collection(project.cat).doc(project.title).set({ screenshots: photoNames }, { merge: true });
    }

  };

  const uploadPhoto = (path, photo, onSuccess) => {
    const uploadTask = storage.ref(path).put(photo);
    uploadTask.on('state_changed',
      (snapshot) => {
        // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        console.log(error);
      },
      () => {
        onSuccess();
      });
  };

  return (
    <div className='main'>
      {model ? 'Edit Project' : 'Add Project'}
      <Form className="add-project-form">
        <FormGroup>
          <Input
            type='text'
            placeholder='Order'
            value={order}
            onChange={(event) => setOrder(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input
            type='select'
            placeholder='Category'
            value={cat}
            onChange={(event) => setCat(event.target.value)}>
            <option>Android</option>
            <option>iOS</option>
            <option>Website</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Input
            readOnly={model ? true : false}
            type='text'
            placeholder='Title'
            value={title}
            onChange={(event) => setTitle(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input
            type='text'
            placeholder='Developed By'
            value={devBy}
            onChange={(event) => setDevBy(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input
            type='text'
            placeholder='Technology'
            value={tech}
            onChange={(event) => setTech(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input
            type='text'
            placeholder='Store Url'
            value={url}
            onChange={(event) => setUrl(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input
            type='textarea'
            placeholder='Short Description'
            value={desc}
            onChange={(event) => setDesc(event.target.value)} />
        </FormGroup>

        <FormGroup>
          <Label>App Icon</Label>
          <div style={{ marginRight: 0, marginBottom: 0 }}>
            <div onClick={() => {
              addIcon.current.click();
            }} style={{ width: 100, height: 100, backgroundColor: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={add} style={{ width: 30, height: 30 }} />
              <input
                type='file'
                style={{ display: 'none' }}
                ref={addIcon}
                onChange={(event) => {
                  setIcon(event.target.files[0]);
                  setIconUrl(URL.createObjectURL(event.target.files[0]))
                }} />
              <img src={iconUrl} style={{ position: 'absolute', width: 100, height: 100 }} />
            </div>
          </div>
        </FormGroup>

        <FormGroup>
          <Label>Main Screenshot</Label>
          <div style={{ marginRight: 0, marginBottom: 0 }}>
            <div onClick={() => {
              addMainShot.current.click();
            }} style={{ width: 100, height: 170, backgroundColor: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={add} style={{ width: 30, height: 30 }} />
              <input
                type='file'
                style={{ display: 'none' }}
                ref={addMainShot}
                onChange={(event) => {
                  setMainShot(event.target.files[0]);
                  setMainShotUrl(URL.createObjectURL(event.target.files[0]))
                }} />
              <img src={mainShotUrl} style={{ position: 'absolute', width: 100, height: 170 }} />
            </div>
          </div>
        </FormGroup>

        <FormGroup>
          <Label>Screenshots</Label>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {showScreenshotsPanel()}
          </div>
        </FormGroup>

        <div style={{ alignSelf: 'center' }}>
          <Rings
            type="ThreeDots"
            color="#00BFFF"
            height={70}
            width={70}
            visible={loader} />
        </div>

        <Button
          className='btn-block btn-dark btn-submit'
          onClick={() => {
            console.log('click');
            if (!model)
              addProject();
            else
              updateProject();
          }}>
          {model ? 'Update Project' : 'Add Project'}
        </Button>

        {model ?
          <Button
            className='btn-block btn-dark btn-submit'
            style={{ backgroundColor: 'red' }}
            onClick={() => {
              console.log('delete');
              setDelAlert(!delAlert);
            }}>
            Delete
          </Button>
          :
          null
        }

        <Alert color="info" isOpen={delAlert} toggle={() => setDelAlert(false)}>
          <p>
            Are you sure you want to delete this project?
          </p>
          <hr />
          <Button
            className='btn-block btn-dark btn-submit'
            style={{ backgroundColor: 'red' }}
            onClick={() => {
              console.log('delete');
              setLoader(true)
              // delete project
              db.collection(model.cat).doc(model.title).delete()
                .then(function () {
                  showToast('success', 'Project Deleted!');
                  setLoader(false);
                  setDelAlert(false);
                }).catch(function (error) {
                  console.error("Error removing document: ", error);
                  setLoader(false);
                  setDelAlert(false);
                });
              // delete all images
              const iconPath = model.cat + '/' + model.title + '/' + iconName;
              storage.ref().child(iconPath).delete();

              const mainShotPath = model.cat + '/' + model.title + '/' + mainShotName;
              storage.ref().child(mainShotPath).delete();

              let arr = model.screenshots.split(',');
              for (let i = 0; i < arr.length; i++) {
                const photoName = arr[i];
                const photoPath = model.cat + '/' + model.title + '/screenshots/' + photoName;
                storage.ref().child(photoPath).delete();
              }
            }}>
            Yes
          </Button>
        </Alert>

      </Form>
      <div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default AddProject;