import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import db, { storage } from '../firebase/firebase';
import { Input } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const Projects = () => {
  const [filter, setFilter] = useState('Android');
  const [models, setModels] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [list, setList] = useState([]);

  const getImageUrl = (path, onSuccess) => {
    storage.ref().child(path).getDownloadURL().then((url) => {
      onSuccess(url);
    })
  }

  const loadData = () => {
    db.collection(filter)
      .orderBy('order', 'asc')
      .get()
      .then(querySnapshot => {
        const projects = querySnapshot.docs.map(doc => doc.data());
        let models = [];
        let photos = [];
        let list = [];
        for (let i = 0; i < projects.length; i++) {
          const project = projects[i];
          models = models.concat(project);
          list.push(project.title);
          photos.push(''); // dummy fill
          // update dummy fill download urls
          getImageUrl(
            project.cat + '/' + project.title + '/' + project.mainShot,
            (url) => {
              console.log('url: ' + url);
              photos[i] = url;
              setPhotos([...photos], photos);
            });
        }
        setModels([...models], models);
        setList(list);
        setPhotos(photos);
      });
  };

  // load data
  useEffect(() => {
    loadData();
  }, [filter]);

  const SortableItem = SortableElement(({ value }) => <div style={{ marginRight: 10, marginBottom: 10, backgroundColor: '#F2F2F2', padding: 5, borderRadius: 5, fontSize: 14 }}>{value}</div>);

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tempList = arrayMoveImmutable(list, oldIndex, newIndex);
    setList(tempList);
    // update orders
    const cat = filter;
    for (let i = 0; i < tempList.length; i++) {
      const title = tempList[i];
      db.collection(cat)
        .doc(title)
        .set({ order: i + 1 }, { merge: true })
    }
    setTimeout(() => {
      loadData();
    }, 4000);
  };

  return (
    <div className='main'>
      <div className='titlebar'>
        <div>
          Projects
        </div>
        <Link
          to='/add-project001'
          state={{
            order: models.length + 1,
            cat: filter,
          }}>
          <button type="button" class="btn btn-primary btn-sm">
            Add Project
          </button>
        </Link>
      </div>

      <Input
        type='select'
        style={{ width: 120, height: 35 }}
        placeholder='Category'
        value={filter}
        onChange={(event) => setFilter(event.target.value)}>
        <option>Android</option>
        <option>iOS</option>
        <option>Website</option>
      </Input>

      <div style={{ marginTop: 20 }}>
        Sorting
      </div>
      <SortableList
        items={list}
        onSortEnd={onSortEnd}
        axis='xy' />

      {models.map((model, index) => {
        let className = index % 2 !== 0 ? 'projects-itembar' : 'projects-itembar-colored'
        return (
          <div className={className}>
            <div className='projects-item-photo'>
              <img src={photos[index]} style={{ padding: 1, width: 95, height: 170, backgroundColor: 'black' }} />
            </div>
            <div className='projects-item-title'>
              {model.title}
            </div>
            <div className='projects-item-cat'>
              <input
                type='text'
                style={{ width: 50, height: 30 }}
                value={model.order}
                onChange={
                  (event) => {
                    // update list
                    models[index].order = event.target.value;
                    setModels([...models], models);

                    if (event.target.value === '')
                      return;
                    // update on firebase
                    const oldOrder = model.order;
                    const newOrder = parseInt(event.target.value);

                    // update new order
                    db.collection(model.cat)
                      .doc(model.title)
                      .set({ order: newOrder }, { merge: true })
                      .then(() => {
                        loadData();
                      })
                      .catch(error => {
                        //
                      });
                  }
                } />
            </div>
            
            <div className='projects-item-btns' style={{ display: 'flex', flexDirection: 'column' }}>
              <Link
                to='/add-project001'
                state={{ model: model }}>
                <button type="button" class="btn btn-primary btn-sm btn-edit">
                  Edit
                </button>
              </Link>
              <Link type="button" class="btn btn-primary btn-sm btn-edit" style={{ marginTop: 5 }} to={'project/' + model.cat + '_' + model.title}>
                View
              </Link>
            </div>
          </div>
        );
      })}

    </div>
  );
}

export default Projects;
