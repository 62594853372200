import React, { Component } from 'react';
import Slider from 'react-slick';

class TestimonialSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings} className="testimonial_slider">

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/raymond.png')} alt="" />
            </div>
            <h6>Raymond V.</h6>
            <span>United States</span>
            <p>Saifal is an outstanding help to our team. He delivered the project well ahead of time. Communication was excellent and the quality of the end product was well above the price for his work.</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/ahmed.png')} alt="" />
            </div>
            <h6>Ahmed G.</h6>
            <span>Egypt</span>
            <p>Faisal is an Excellent iOS developer, quick, reliable, and very patient. Very creative did what I asked and more and never complains and very affordable. Already hired him again for a second project. Very recommended.</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/danstan.png')} alt="" />
            </div>
            <h6>Danstan O.</h6>
            <span>Kenya</span>
            <p>Fast and creative! Saifal N. gave suggestions that made my project great. He beat my deadlines and always agreed to make changes where needed. We worked as a team and that made him manageable and comfortable to work with! Will hire again anytime!</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/alexandar.png')} alt="" />
            </div>
            <h6>Alexander</h6>
            <span>Germany</span>
            <p>Great service! Fast respond time, very good service all in all.</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/dr.png')} alt="" />
            </div>
            <h6>Dr. Subodh</h6>
            <span>India</span>
            <p>The Guy knows what he is doing, is professional and goes extra mile to help you out. Will definitely hire him again.</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/pslocks.png')} alt="" />
            </div>
            <h6>Pslocks</h6>
            <span>Austria</span>
            <p>I got the right app in the best possible time frame. great!</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/chris.png')} alt="" />
            </div>
            <h6>Chris G.</h6>
            <span>Britain</span>
            <p>Really knew his stuff and a real pleasure to work with - thank you!</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/olumide.png')} alt="" />
            </div>
            <h6>Olumide A.</h6>
            <span>Australia</span>
            <p>Saifal was professional in all dealing. I love working with him. He made the collaboration aspect easy. Thanks for the good job</p>
          </div>

          <div className="item">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img className="testimonial_img" src={require('../../image/hozefa.png')} alt="" />
            </div>
            <h6>Hozefa B.</h6>
            <span>India</span>
            <p>very experienced in this field.. completed work in before time limits.</p>
          </div>

        </Slider>
      </div>
    );
  }
}
export default TestimonialSlider;