import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import Lottie from 'react-lottie';
import anim1 from '../Lottie/anim1.json';
import anim2 from '../Lottie/anim2.json';
import anim4 from '../Lottie/anim4.json';
import anim5 from '../Lottie/anim5.json';
import anim6 from '../Lottie/anim6.json';
import anim7 from '../Lottie/anim7.json';
import anim8 from '../Lottie/anim8.json';
import anim10 from '../Lottie/anim10.json';
import showToast from '../toast';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'

const loaders = [
    'Style 1',
    'Style 2',
    'Style 3',
    'Style 4',
    'Style 5',
    'Style 6',
    'Style 7',
    'Style 8',
]

const Step3 = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    let profilePercentage = 75
    let mobileWidth = 175
    let mobileHeight = 300

    const [color, setColor] = useState('#f2f2f2');
    const [iconTint, setIconTint] = useState('Gray');
    const iconTints = ['Dark', 'Gray', 'Light']
    const [showTitles, setShowTitles] = useState(false);
    const [loader, setLoader] = React.useState(loaders[2]);

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step3) {
                setColor(shopifyData.step3.tabBarColor)
                setIconTint(shopifyData.step3.iconTint)
                setShowTitles(shopifyData.step3.showTitles)
                setLoader(shopifyData.step3.loader)
                console.log('1: ' + shopifyData.step3.loader);
            }
            else if (shopifyData.step2) {
                setLoader(shopifyData.step2.loader)
                console.log('2: ' + shopifyData.step2.loader);
            }
        }
    }, [])

    const getAnim = () => {
        if (loader === loaders[0])
            return anim1
        else if (loader === loaders[1])
            return anim2
        else if (loader === loaders[2])
            return anim4
        else if (loader === loaders[3])
            return anim5
        else if (loader === loaders[4])
            return anim6
        else if (loader === loaders[5])
            return anim7
        else if (loader === loaders[6])
            return anim8
        else if (loader === loaders[7])
            return anim10
    };

    const getAnimSizeMargin = () => {
        if (loader === loaders[0])
            return { size: 70, marginTop: 10 }
        else if (loader === loaders[1])
            return { size: 70, marginTop: 0 }
        else if (loader === loaders[2])
            return { size: 60, marginTop: 10 }
        else if (loader === loaders[3])
            return { size: 40, marginTop: 25 }
        else if (loader === loaders[4])
            return { size: 45, marginTop: 20 }
        else if (loader === loaders[5])
            return { size: 60, marginTop: 20 }
        else if (loader === loaders[6])
            return { size: 40, marginTop: 25 }
        else if (loader === loaders[7])
            return { size: 70, marginTop: 10 }
        return 0
    };

    return (
        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            
            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={() => {
                    window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store to a Mobile App.&phone=+923074052841", '_blank')
                }}>
                <img
                    src={require('../../image/wa1.png')}
                    style={{ alignSelf: 'center', width: 35, height: 35, tintColor: '#04B486', marginTop: -3 }}
                    alt=""
                />
            </button>

            <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
            <div style={{ display: 'flex', marginTop: 100, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ width: 70, height: 70 }}
                    src={require('../../image/shopify.png')} />
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginTop: 30, marginLeft: 10, color: '#113E49' }}>
                    Mobile App Builder
                </h3>
            </div>
            <div className='shopify-step-parent' style={{ overflow: 'scroll', paddingBottom: 80, display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div style={{ height: 8, width: '100%', backgroundColor: '#f2f2f2', marginTop: 50, borderRadius: 4 }}>
                    <div style={{ height: 8, backgroundColor: '#0db3c7', width: profilePercentage + '%', borderRadius: 4 }} />
                </div>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>

                    <div style={{ display: 'flex', paddingRight: 10, flexWrap: 'wrap', justifyContent: 'center' }}>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', minWidth: 300, marginRight: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, padding: 0, backgroundColor: '#fff', border: '0px solid lightgray', marginRight: 20, borderRadius: 5 }}>

                                    <div
                                        style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>
                                        Customize Bottom Tabs & Page Loader
                                    </div>
                                    <div
                                        style={{ fontSize: 14, marginTop: 0 }}>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                                        <div style={{ marginRight: 20 }}>Tab Icons</div>
                                        <select
                                            name="icon"
                                            id="icon"
                                            value={iconTint}
                                            style={{ height: 45, border: '0px', minWidth: 160, paddingLeft: 10 }}
                                            onChange={(event) => {
                                                setIconTint(event.target.value)
                                            }}>
                                            {iconTints.map((item, index) => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </select>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, justifyContent: 'space-between' }}>
                                        <div style={{ marginTop: 6, marginRight: 20 }}>Tab Bar Color</div>
                                        <input
                                            type="color"
                                            value={color}
                                            onChange={(event) => {
                                                setColor(event.target.value)
                                            }}
                                            style={{ border: '0px solid green', width: 160, height: 45 }} />
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 12 }}>
                                        <div style={{ marginRight: 20 }}>Show Labels</div>
                                        <input
                                            type='checkbox'
                                            style={{ width: 20, height: 20 }}
                                            onChange={(event) => {
                                                setShowTitles(event.target.checked)
                                            }} />
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                                        <div style={{ marginRight: 20 }}>Page Loader: </div>
                                        <select
                                            name="loader"
                                            id="loader"
                                            value={loader}
                                            style={{ height: 45, border: '0px', minWidth: 160, paddingLeft: 10 }}
                                            onChange={(event) => {
                                                setLoader(event.target.value)
                                            }}>
                                            {loaders.map((item, index) => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 20, borderRadius: 11, backgroundColor: 'black', paddingLeft: 3, paddingRight: 3, paddingTop: 3, paddingBottom: 10 }}>
                            <div style={{ width: 25, height: 3, backgroundColor: 'lightgray', marginTop: 5, marginBottom: 5 }} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: mobileWidth, height: mobileHeight, borderRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, backgroundColor: '#fff' }}>
                                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <div style={{ marginTop: getAnimSizeMargin().marginTop }}>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: getAnim(),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={getAnimSizeMargin().size}
                                            width={getAnimSizeMargin().size}
                                        />
                                    </div>
                                    {/* <div style={{ textAlign: 'center', fontSize: 16, lineHeight: 1.5 }}>
                                    Your Store's<br />Home Page
                                </div> */}
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', height: 35, backgroundColor: color }}>
                                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <img
                                            style={{ width: 17, height: 17, opacity: iconTint === 'Gray' ? 0.4 : 1, marginTop: showTitles ? 11 : 0 }}
                                            src={iconTint === 'Light' ? require('../../image/hut7.png') : require('../../image/hut6.png')} />
                                        {showTitles &&
                                            <div
                                                style={{
                                                    fontSize: 9, marginTop: -9,
                                                    color: iconTint === 'Light' ? 'white' : iconTint === 'Gray' ? 'gray' : 'black'
                                                }}>
                                                Home
                                            </div>
                                        }
                                    </div>
                                    <div style={{ flex: 1, flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img
                                            style={{ width: 15, height: 15, opacity: iconTint === 'Gray' ? 0.4 : 1, marginTop: showTitles ? 11 : 0 }}
                                            src={iconTint === 'Light' ? require('../../image/star2.png') : require('../../image/star1.png')} />
                                        {showTitles &&
                                            <div
                                                style={{
                                                    fontSize: 9, marginTop: -8,
                                                    color: iconTint === 'Light' ? 'white' : iconTint === 'Gray' ? 'gray' : 'black'
                                                }}>
                                                Rate
                                            </div>
                                        }
                                    </div>
                                    <div style={{ flex: 1, flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img
                                            style={{ width: 15, height: 15, opacity: iconTint === 'Gray' ? 0.4 : 1, marginTop: showTitles ? 12 : 0 }}
                                            src={iconTint === 'Light' ? require('../../image/share2.png') : require('../../image/share1.png')} />
                                        {showTitles &&
                                            <div
                                                style={{
                                                    fontSize: 9, marginTop: -9,
                                                    color: iconTint === 'Light' ? 'white' : iconTint === 'Gray' ? 'gray' : 'black'
                                                }}>
                                                Share
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div style={{ width: 25, height: 25, backgroundColor: 'lightgray', marginTop: 10, borderRadius: 15 }} />
                        </div>

                    </div>

                    <div style={{ display: 'flex', minWidth: 280, flex: 1, flexDirection: 'column' }}>
                        <Link
                            onClick={() => {
                                console.log('click');
                                let data = {
                                    tabBarColor: color,
                                    iconTint: iconTint,
                                    showTitles: showTitles,
                                    loader: loader,
                                }
                                let shopifyDataTemp = { ...shopifyData }
                                shopifyDataTemp['step3'] = data
                                dispatch(Actions.setShopifyData(shopifyDataTemp))
                                navigate('/shopify-plans', { replace: false });
                            }}
                            className='btn my-button' style={{ height: 55, backgroundColor: '#0db3c7', color: 'white', marginTop: 20, width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                            Continue
                        </Link>
                    </div>

                </div>

            </div>
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default Step3; 