import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyARLHBSGw-PqDinM5IAcwy6QWhiIKbztEA",
  authDomain: "",
  databaseURL: "",
  projectId: "adminapp-31c2d",
  storageBucket: "gs://adminapp-31c2d.appspot.com",
  messagingSenderId: "178182885449"
});
const db = firebaseApp.firestore();
export const storage = firebase.storage()
export default db;