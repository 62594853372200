import React, { useEffect } from 'react';
import FooterTwo from '../component/FooterTwo';
import NavbarShopifyToApp from './Navbar/NavbarShopifyToApp';
import jhonData from '../component/jhonData';
import { useNavigate } from 'react-router-dom';

const Support = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        //
    }, [])

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" /> */}

            <div className='shopify-step-parent' style={{ height: '100vh', display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 100, display: 'flex', flexDirection: 'column' }}>
                    <h3 style={{fontFamily:'sans-serif'}}>
                        Welcome to SaifTech Customer Support!
                    </h3>
                    <div>
                        In case of any issues or complaints please contact us via:
                    </div>
                    <div style={{ color: 'blue' }}>
                        <span style={{ color: 'black' }}>Email:</span> saif052m@gmail.com
                    </div>
                    <div style={{ color: 'blue' }}>
                        <span style={{ color: 'black' }}>Whatsapp:</span> +923074052841
                    </div>
                </div>

            </div>
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default Support;