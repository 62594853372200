import React, { Component } from 'react';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import Slider from 'react-slick';
import db, { storage } from '../../firebase/firebase';
import { Rings } from 'react-loader-spinner';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import {getMobileView} from '../../mobile_view';

class PortfolioSlider extends Component {
 
    state = {
        portType: 'Android',
        models: [],
        photos: [],
        loader: true,
    }

    componentDidMount() {
        this.loadData();
    }

    getImageUrl = (path, onSuccess) => {
        storage.ref().child(path).getDownloadURL().then((url) => {
            onSuccess(url);
        })
    }

    loadData = () => {
        this.setState({ loader: true });
        db.collection(this.state.portType)
            .orderBy('order', 'asc')
            .get()
            .then(querySnapshot => {
                const projects = querySnapshot.docs.map(doc => doc.data());
                console.log('size: ' + projects.length);
                let models = [];
                let photos = [];
                for (let i = 0; i < projects.length; i++) {
                    const project = projects[i];
                    models = models.concat(project);
                    photos.push(''); // dummy fill
                    // update dummy fill download urls
                    this.getImageUrl(
                        project.cat + '/' + project.title + '/' + project.mainShot,
                        (url) => {
                            console.log('url: ' + url);
                            photos[i] = url;
                            this.setState({ photos: photos })
                            // setPhotos([...photos], photos);
                        });
                }
                this.setState({ models: models, photos: photos, loader: false })
                // setModels([...models], models);
                // setPhotos(photos);
            });
    };

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: 3000,
            slidesToShow: this.state.portType === 'Website' ? 1 : 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="portfolio_slider_area" style={{ paddingTop: 50, paddingBottom: 50 }} id="portfolio">
                <div className="container">
                    <SectionTitleTwo tCenter="text-center" stitle="Portfolio" btitle="Some of our works" />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: -20, marginBottom: 40 }}>
                        <div class="btn-group" role="group" aria-label="...">
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'Website' });
                                    setTimeout(() => {
                                        this.loadData();
                                    }, 50);
                                }}
                                type="button"
                                class={this.state.portType === 'Website' ? "btn btn-info" : "btn btn-light"}
                                style={{ width: 150, color: this.state.portType === 'Website' ? 'white':'black' }}>
                                Web
                            </button>
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'Android' });
                                    setTimeout(() => {
                                        this.loadData();
                                    }, 50);
                                }}
                                type="button"
                                class={this.state.portType === 'Android' ? "btn btn-info" : "btn btn-light"}
                                style={{ width: 150, color: this.state.portType === 'Android' ? 'white':'black' }}>
                                Android
                            </button>
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'iOS' });
                                    setTimeout(() => {
                                        this.loadData();
                                    }, 50);
                                }}
                                type="button"
                                class={this.state.portType === 'iOS' ? "btn btn-info" : "btn btn-light"}
                                style={{ width: 150, color: this.state.portType === 'iOS' ? 'white':'black' }}>
                                iOS
                            </button>
                        </div>
                    </div>
                    {this.state.loader ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Rings
                                type="ThreeDots"
                                color="#20b4c6"
                                height={70}
                                width={70}
                                visible={true} />
                        </div>
                        :
                        <Slider {...settings} className="portfolio_slider">
                            {
                                this.state.models.map((project, index) => {
                                    console.log('project: '+project.cat+'_'+project.title);
                                    if(project.cat+'_'+project.title === 'Website_Saiftech'){
                                        return null
                                    }
                                    return (
                                        <Fade bottom cascade duration={500}>
                                            <div className="p_item">
                                                {/* <Link to={'project/'+project.cat+'_'+project.title}> */}
                                                    <div className="portfolio_content">
                                                        {getMobileView(this.state.photos[index], project.cat)}
                                                        {/* <div className="text" style={{ textAlign: 'center' }}>
                                                            <p style={{ fontSize: 16, marginTop: 0, color:'gray' }}>{project.title}</p>
                                                        </div> */}
                                                    </div>
                                                {/* </Link> */}
                                            </div>
                                        </Fade>
                                    )
                                })
                            }
                        </Slider>
                    }
                </div>
            </section>
        )
    }
}

export default PortfolioSlider;